import Typography from "@mui/material/Typography";
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';


interface ModifiedByUser {
    id: string;
    title: string | null;
    first_name: string | null;
    last_name: string | null;
    nickname: string | null;
}

interface PropTypes<T extends ModifiedByUser = ModifiedByUser> {
    prefix: string;
    date?: string;
    user?: T;
}

dayjs.extend(calendar);

export default function DateModified({ prefix, date, user }: PropTypes) {
    const getUserName = (user: ModifiedByUser) => {
        if (!user) {
            return '';
        }

        return ` by ${user.nickname ?? user.first_name ?? ''} ${user.last_name ?? ''}`;
    };

    if (!date) {
        return null
    }

    return (
        <Typography variant="subtitle2">
            <strong>{prefix}</strong>
            {`${dayjs(date).calendar(null, {
                sameElse: 'MMM D, YYYY [at] h:mm a',
            })} ${getUserName(user)}`}
        </Typography>
    );
}