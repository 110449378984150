import { createQuery } from 'react-query-kit';
import { ErrorResponse, privateApi } from '../api';
import { AxiosError } from 'axios';

export type GetChargeParams = {
    id: string;
}

type ModifiedByUser = {
    id: string;
    title: string | null;
    first_name: string | null;
    last_name: string | null;
    nickname: string | null;
};

export type Charge = {
    tenant_id: string;
    id: string;
    student_user_id: string;
    date: Date;
    amount: number;
    memo?: string | null;
    description?: string | null;
    private_notes?: string | null;
    amount_due?: string | null;
    amount_due_lock?: string | null;
    deposit: number;
    deposit_due?: string | null;
    deposit_due_lock?: string | null;
    voided: boolean;
    reason_voided?: string | null;
    voided_by?: string | null;
    voided_at?: string | null;
    created_by?: string | null;
    des_id?: string | null;
    reason?: "enrollment" | "absence" | "no_permit" | "cancellation" | "late_cancellation" | "enrollment_transfer" | "course_extension" | null;
    created_at: string | null;
    updated_by: string | null;
    updated_at: string | null;
    voided_by_user: ModifiedByUser | null;
    created_by_user: ModifiedByUser | null;
    updated_by_user: ModifiedByUser | null;
    student_charge_discounts: {
        tenant_id: string;
        id: string;
        student_charge_id: string;
        discount_id: string;
        order: number;
        type: "fixed" | "percentage";
        amount: number;
        notes?: string | null;
        private_notes?: string | null;
        discount: {
            tenant_id: string;
            id: string;
            name: string;
            default_type: "fixed" | "percentage";
            default_amount: number;
            deleted: boolean;
        };
    }[]
}

export type GetChargeResult = Charge;

async function getCharge(params: GetChargeParams): Promise<GetChargeResult | null> {

    if (!params.id) {
        return null;
    }

    // make api call
    const result = await privateApi.get(`${import.meta.env.VITE_API_BASE_URL}/finance/charge/${params.id}`);

    // return the data object
    return result.data as GetChargeResult | null;
}

const useGetCharge = createQuery<GetChargeResult, GetChargeParams, AxiosError<ErrorResponse>>({
    primaryKey: 'charge',
    queryFn: ({ queryKey: [, variables] }) => getCharge(variables),
})

export { useGetCharge };

