import { AxiosError } from "axios";
import { createMutation } from "react-query-kit";
import { privateApi } from '../api';
import { ChargeFormValues } from "@/components/Dialogs/useChargeDialog/ChargeSchema";
import { Charge } from "./GetCharge";
import { ApplicationError } from "../ApplicationError";

export type UpdateChargeResult = Charge | null;
export type UpdateChargeParams = ChargeFormValues;

async function updateCharge(params: UpdateChargeParams): Promise<UpdateChargeResult> {

    // ensure charge has an id
    if (!params?.id) {
        throw new Error('Charge id is required');
    }

    // make api call
    const result = await privateApi.put(`${import.meta.env.VITE_API_BASE_URL}/finance/charge/${params?.id}`, params);

    // return the data object
    return result.data as Charge | null;
}

const useUpdateCharge = createMutation<UpdateChargeResult, UpdateChargeParams, AxiosError<ApplicationError>>({
    mutationFn: async (variables: UpdateChargeResult) => updateCharge(variables),
});

export { updateCharge, useUpdateCharge };
