import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid2';
import { useFormContext } from 'react-hook-form';
import ChargeSummaryItem from './ChargeSummaryItem';
import { DiscountFieldValue } from '../ChargeSchema';
import { useGetStudentLedger } from '@/api/users/students/GetStudentLedger';
import { useEffect, useState } from 'react';

function ChargeSummary() {
    const [newBalance, setNewBalance] = useState(0);
    const { watch, formState: { errors } } = useFormContext();

    const { data: studentLedger } = useGetStudentLedger({
        variables: {
            id: watch('student_user_id'),
        },
        initialData: [],
    });

    const amount = !Number.isNaN(watch('amount')) ? watch('amount') ?? 0 : 0;
    const deposit = !Number.isNaN(watch('deposit')) ? watch('deposit') ?? 0 : 0;
    const discounts = watch('student_charge_discounts') ?? [];

    console.log('Amount: ', amount);
    console.log('Deposit: ', deposit);
    console.log('Discounts: ', discounts);

    const finalTotal = discounts?.reduce((accumulator, discount: DiscountFieldValue) => {
        if (discount?.type === 'fixed') {
            return {
                discountTotal: accumulator.discountTotal + (discount?.amount / 100),
                discountedTotal: Math.max(0, accumulator.discountedTotal - (discount?.amount / 100)),
            }
        }

        if (discount?.type === 'percentage') {
            const discountAmount = (discount?.amount / 100) * accumulator.discountedTotal;
            return {
                discountTotal: accumulator.discountTotal + discountAmount,
                discountedTotal: Math.max(0, accumulator.discountedTotal - discountAmount),
            }
        }

        return accumulator;
    }, {
        discountTotal: 0,
        discountedTotal: amount / 100,
    });

    useEffect(() => {
        // calculate the new account balance excluding the current charge
        const currentChargeId = watch('id');
        const currentBalance = studentLedger.reduce((acc, entry) => {

            if (entry.voided || entry.id === currentChargeId) {
                return acc;
            }

            return acc + Number(entry.debit) + Number(entry.credit);
        }, 0);
        setNewBalance(currentBalance + finalTotal?.discountedTotal);
    }, [finalTotal]);
    console.log(errors)

    return (
        <Grid container rowSpacing={0} columnSpacing={3}>
            <ChargeSummaryItem title="Subtotal" value={(amount / 100).toFixed(2)} />
            <ChargeSummaryItem title="Discounts" value={!Number.isNaN(finalTotal?.discountTotal) ? finalTotal?.discountTotal.toFixed(2) : ''} />
            <Grid size={12} py={1}><Divider /></Grid>
            <ChargeSummaryItem title="Total" value={!Number.isNaN(finalTotal?.discountedTotal) ? finalTotal?.discountedTotal?.toFixed(2) : '0.00'} />
            <ChargeSummaryItem title="Deposit" value={!Number.isNaN(deposit) ? (deposit / 100).toFixed(2) : ''} error={!!errors['deposit']} />
            <Grid size={12} py={1}><Divider /></Grid>
            <ChargeSummaryItem title="New Student Balance" value={newBalance.toFixed(2)} />
        </Grid>
    );
}

export default ChargeSummary;