import { AxiosError } from "axios";
import { createMutation } from "react-query-kit";
import { privateApi } from '../api';
import { ChargeFormValues } from "@/components/Dialogs/useChargeDialog/ChargeSchema";
import { Charge } from "./GetCharge";
import { ApplicationError } from "../ApplicationError";

export type CreateChargeResult = Charge | null;
export type CreateChargeParams = ChargeFormValues;

async function CreateCharge(params: CreateChargeParams): Promise<CreateChargeResult> {

    // make api call
    const result = await privateApi.post(`${import.meta.env.VITE_API_BASE_URL}/finance/charge`, params);

    // return the data object
    return result.data as Charge | null;
}

const useCreateCharge = createMutation<CreateChargeResult, CreateChargeParams, AxiosError<ApplicationError>>({
    mutationFn: async (variables: CreateChargeResult) => CreateCharge(variables),
});

export { CreateCharge, useCreateCharge };
