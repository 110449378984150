import { FieldPath, useForm, useFormContext, useFormState, useWatch } from "react-hook-form";

import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import SelectInput from "@/components/Forms/SelectInput";

import useMediaQuery from "@mui/material/useMediaQuery";
import useTheme from "@mui/material/styles/useTheme";

import { ChargeFormValues } from "../ChargeSchema";
import { useEffect, useState } from "react";
import NumericFormatInput from "@/components/Forms/NumericFormatInput";
import InputAdornment from "@mui/material/InputAdornment";
import { Discount, useGetDiscounts } from "@/api/finance/GetDiscounts";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

const TypeOptions = [
    {
        label: 'Fixed',
        value: 'fixed'
    },
    {
        label: 'Percentage',
        value: 'percentage'
    }
];


interface PropTypes {
    index: number;
    name: FieldPath<ChargeFormValues>;
    remove: (index?: number | number[]) => void
}

// interface Discount {
//     id: string;
//     name: string;
//     type: 'fixed' | 'percentage';
//     amount: number;
// }

function DiscountField({ index, name, remove }: PropTypes) {
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });
    const { data: discounts } = useGetDiscounts();
    const { setValue } = useFormContext<ChargeFormValues>();
    const [discount, setDiscount] = useState<Discount | undefined>(undefined);
    const { errors } = useFormState<ChargeFormValues>({ name: name });
    const discountField = useWatch({ name: name });

    useEffect(() => {
        const selectedDiscount = discounts?.find(obj => obj.id === discountField?.discount_id);
        console.log('Selected Discount: ', selectedDiscount);

        if (selectedDiscount) {
            setDiscount(selectedDiscount);
            setValue(`${name}.type` as FieldPath<ChargeFormValues>, selectedDiscount.default_type);
            setValue(`${name}.amount` as FieldPath<ChargeFormValues>, selectedDiscount.default_amount);
        }

    }, [discountField?.discount_id]);

    useEffect(() => {
        if (discountField?.type === 'percentage') {
            setValue(`${name}.amount` as FieldPath<ChargeFormValues>, 0);
        }
    }, [discountField?.type]);

    return (
        <Grid container rowSpacing={1} columnSpacing={1}>
            <Grid size={12} sx={{ mb: 0 }}>
                <Typography
                    variant='h5'
                    sx={{
                        fontWeight: 'bold',
                        textDecoration: 'underline'
                    }}
                >
                    {discount?.name ?? `Discount ${discountField?.order}`}
                </Typography>
                <Typography color="error" fontWeight="bold">
                    {errors[name]?.root?.message}
                </Typography>
                {/* <Typography color="error" fontWeight="bold">
                    {JSON.stringify(discount)}
                </Typography> */}
            </Grid>

            {/* <Grid size={1} alignSelf="flex-end">
                <IconButton
                    color="info"
                    aria-label="reorder"
                    sx={{
                        paddingLeft: 0
                    }}
                >
                    <DragIndicatorIcon  />
                </IconButton>
            </Grid> */}

            <Grid size={{ xs: 12, md: 4 }}>
                <SelectInput
                    name={`${name}.discount_id`}
                    label="Discount"
                    options={discounts?.map(discount => {
                        return ({ label: discount.name, value: discount.id });
                    })}
                    sx={{
                        minWidth: '100px'
                    }}
                />
            </Grid>

            <Grid size={{ xs: 12, md: 4 }}>
                <SelectInput
                    name={`${name}.type`}
                    label="Type"
                    options={TypeOptions}
                    sx={{
                        minWidth: '100px'
                    }}
                />
            </Grid>

            <Grid size={{ xs: 12, md: 3 }}>
                {/* {discount?.type === 'fixed' && (
                    <CurrencyInput
                        name={`${name}.amount`}
                        label="Amount"
                    />
                )} */}

                <NumericFormatInput
                    name={`${name}.amount`}
                    label="Amount"
                    multiplier={discountField?.type === 'fixed' ? 100 : 1}
                    numericFormatProps={{
                        allowLeadingZeros: false,
                        allowNegative: false,
                        decimalScale: discountField?.type === 'fixed' ? 2 : 0,
                        fixedDecimalScale: true,
                        thousandSeparator: ',',
                        thousandsGroupStyle: 'thousand',
                        isAllowed: ({ floatValue }) => {
                            return (discountField?.type === 'fixed' || floatValue <= 100);
                        },
                    }}
                    slotProps={{
                        input: {

                            startAdornment: discountField?.type === 'fixed' && <InputAdornment position="start">$</InputAdornment>,
                            endAdornment: discountField?.type === 'percentage' && <InputAdornment position="end">%</InputAdornment>,
                        },
                    }}
                />
            </Grid>

            <Grid size={{ xs: 12, md: 1 }} alignSelf="flex-end" >
                {isMd && (
                    <IconButton
                        color="error"
                        aria-label="delete"
                        onClick={() => remove(index)}
                    >
                        <DeleteIcon />
                    </IconButton>
                )}

                {!isMd && (
                    <Button
                        variant="contained"
                        size="small"
                        color="error"
                        fullWidth={!isMd}
                        onClick={() => remove(index)}
                    >
                        Remove Discount {index + 1}
                    </Button>
                )}
            </Grid>

        </Grid>
    );
}

export default DiscountField;