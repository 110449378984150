import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';

interface PropTypes {
    title: string;
    value: string;
    error?: boolean;
}

function ChargeSummaryItem({ title, value, error = false }: PropTypes) {
    const sanitizedTitle = title.toLowerCase().replace(/\s+/g, '-');
    return (
        <>
            <Grid size={9} display="flex" justifyContent="flex-end">
                <Typography
                    variant="h4"
                    color={error ? 'error' : undefined}
                    data-testid={`charge-summary-item-${sanitizedTitle}-title`}
                >
                    {title}:
                </Typography>
            </Grid>

            <Grid size={3} display="flex" justifyContent="flex-end">
                <Typography
                    variant="h4"
                    color={error ? 'error' : undefined}
                    data-testid={`charge-summary-item-${sanitizedTitle}-value`}
                >
                    {value}
                </Typography>
            </Grid>
        </>
    )
}

export default ChargeSummaryItem;