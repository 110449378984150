import { FieldArrayPath, useFieldArray } from "react-hook-form";
import Grid from '@mui/material/Grid2';
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { ChargeFormValues } from "../ChargeSchema";
import DiscountField from "./DiscountField";
import { useGetDiscounts } from "@/api/finance/GetDiscounts";

interface PropTypes {
    name: FieldArrayPath<ChargeFormValues>;
}

function DiscountFieldArray({ name }: PropTypes) {
    const { data: discounts } = useGetDiscounts();
    const fieldArray = useFieldArray({ name: name });

    return (
        <Grid container rowSpacing={3} columnSpacing={1}>
            {fieldArray.fields.map((field, index) => {
                console.log('Field: ', field);
                return (
                    <DiscountField key={field?.id} index={index} name={`${name}.${index}`} remove={fieldArray.remove} />
                );
            })}

            {fieldArray.fields.length === 0 && (
                <Grid size={12}>
                    <Typography
                        variant='h5'
                        sx={{
                            fontWeight: 'bold',
                            textDecoration: 'underline'
                        }}
                    >
                        No Discounts Applied
                    </Typography>
                </Grid>
            )}

            <Grid size={12}>
                <Button variant="contained" size="small" fullWidth onClick={() => {
                    const discount = discounts?.[0];
                    fieldArray.append({
                        id: null,
                        discount_id: discounts?.[0]?.id,
                        order: fieldArray.fields.length + 1,
                        type: discounts?.[0]?.default_type,
                        amount: discount?.default_amount,
                    });
                }}>Add Discount</Button>
            </Grid>

        </Grid>
    );
}

export default DiscountFieldArray;